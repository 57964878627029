<template>
  <div w="full" relative bg="white">
    <div
      class="h-60 w-full" flex="~ justify-center items-center" p="b-12"
      :style="{
        background: 'center center no-repeat',
        backgroundSize: 'cover',
        backgroundColor: 'var(--tc-color-bg-index)',
        backgroundImage: `url(${bgImg})`,
      }"
    >
      <div class="px-6 text-center text-white font-600">
        <div class="whitespace-pre-wrap text-4xl leading-normal">
          {{ coinsTitle }}
        </div>
        <div class="mt-3 whitespace-pre-wrap text-xl">
          {{ coinsSubTitle }}
        </div>
      </div>
    </div>
    <div max-w="300" m="x-auto">
      <!-- 搜索框 -->
      <DesktopHomeSearchInCoins @search="handleSearch" />

      <!-- 如何赚取 -->
      <BusinessBHowToEarnInCoins mb-5 mt-6 />

      <!-- 合作品牌 -->
      <BusinessBPartnerBrands />

      <!-- 热门目的地 -->
      <DesktopPopularDestinations m-b-66px />
    </div>
    <!-- 底部介绍 -->
    <DesktopFooter />
  </div>
</template>

<script lang="ts" setup>
import { ENUM_EventType, ENUM_PageName } from '~/enums'
import type { SearchClickEvent } from '~/types/event-tracking'

const { t } = useI18n()

useSeoMeta({
  title: t('key.attractions.index.title'),
  description: t('key.attractions.index.descrition'),
})

const { coinsTitle, coinsSubTitle } = useUseBurnCoins()

const gtm = useGtm()
const filterConditionsStore = useFilterConditionsStore()
const dayjs = useDayjs()
const { locale } = useLocale()

const { query } = useRoute()
const { bgImg } = usePartnerConfig()

function handleSearch() {
  const eventParams: SearchClickEvent = {
    event: ENUM_EventType.Search,
    page_category: ENUM_PageName.Home,
    city: filterConditionsStore.filterConditions.selectedCity?.destinationName,
    city_id: filterConditionsStore.filterConditions.selectedCity?.destinationId,
    country_code: filterConditionsStore.filterConditions.selectedCity?.countryCode,
    search_date: dayjs(filterConditionsStore.filterConditions.date).format('YYYY-MM-DD'),
    guest_number: generateGuestNumber(filterConditionsStore.filterConditions.listTagList),
    locale: locale.value,
    campaign_id: query.campaign_id as string ?? '',
    campaign_name: query.campaign_name as string ?? '',
  }

  gtm?.trackEvent(eventParams)
}
</script>
